import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';

import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';

import { Navigation, Footer } from 'Components/base';
import { Home, Upload, PageNotFound } from 'Components/pages';
import './App.scss';

Amplify.configure(awsconfig);


const AppContainer = () => {

  return (
    <Container className={classNames("app-container")}>
      <Navigation />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/upload' element={<Upload />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Container>
  );
};

const AppContainerLogin = ({isLoadingUser}) => {
  if (!isLoadingUser) {
    Auth.federatedSignIn();
  };
  
  return null;
};


const App = () => {
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {
    const authListenerCallback = ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case 'signIn':
          getCurrentUser().then(userData => assignCurrentUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          console.log('Sign in failure:', data);
          break;
        default:
          break;
      }
    };
    // Listen to the 'auth' channel
    Hub.listen('auth', authListenerCallback);
    // When loading page, if user is already authenticated set the user
    getCurrentUser().then(userData => assignCurrentUser(userData));
  }, []);

  const getCurrentUser = () => {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(err => console.log(err));
  };

  const assignCurrentUser = userData => {
    setUser(userData);
    setIsLoadingUser(false);
  };

  return (
    <>
      {user ? (
        <>
          <Router>
            <AppContainer />
          </Router>

        </>
      ) : (
        <>
          <Router>
            <AppContainerLogin isLoadingUser={isLoadingUser} />
          </Router>
        </>
      )}
    </>
  );
}


export default App;