import Plot from 'react-plotly.js';
import { dfToObjectList} from 'src/utils';

const getHoverText = row => {
  return `
<b>Dataset</b>: ${row['Dataset']}<br>
<b>Super Pathway</b>: ${row['Super Pathway']}<br>
<b>Sub Pathway</b>: ${row['Sub Pathway']}<br>
<b>Biochemical Name</b>: ${row['Biochemical Name']}<br>
<b>ratio</b>: ${row['ratio']}<br>
<b>p-value</b>: ${row['p-value']}<br>
<b>q-value</b>: ${row['q-value']}`;
};

const PlotlyVolcanoChart = ({ df, signMetric }) => {

  if ((df !== undefined)) {
    if (df.columns.includes(signMetric)) {
      const x = df['ratio'].values;
      const y = df[signMetric].values;
      const color = df['color'].values.map(c => (c === 'White') ? 'Black' : c);
      const text = dfToObjectList(df, df.columns).map(row => getHoverText(row));
      return (
        <Plot
          data={[
            {
              x: x,
              y: y,
              text: text,
              type: 'scatter',
              mode: 'markers',
              marker: { color: color },
              hoverinfo: "text"
            }
          ]}
          layout={{
            hoverlabel: { align: 'left'},
            xaxis: {
              type: 'log',
              autorange: true,
              dtick: 1,
              title: {
                text: 'ratio',
                font: {
                  family: 'Fira Sans Regular',
                  size: 18,
                  color: '#7f7f7f'
                }
              }
            },
            yaxis: {
              type: 'log',
              autorange: 'reversed',
              dtick: 1,
              title: {
                text: signMetric,
                font: {
                  family: 'Fira Sans Regular',
                  size: 18,
                  color: '#7f7f7f'
                }
              }
            },
            shapes: [
              {
                type: 'line',
                xref: 'paper',
                x0: 0,
                y0: 0.1,
                x1: 1,
                y1: 0.1,
                line:{
                    color: 'grey',
                    width: 4,
                    dash:'dot'
                }
              },
              {
                type: 'line',
                xref: 'paper',
                x0: 0,
                y0: 0.05,
                x1: 1,
                y1: 0.05,
                line:{
                    color: 'grey',
                    width: 4,
                    dash:'dot'
                }
              }
            ]
          }}
          useResizeHandler={true}
          style={{ width: '100%', height: '100%' }}
        />
      );
    } else {
      return <p>{signMetric} not available.</p>
    }
  }
  return null;
}

export default PlotlyVolcanoChart;