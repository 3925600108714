import { Button, Modal } from 'react-bootstrap';


const CytoscapeModal = ({show, handleClose}) => {

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>User guide</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The selectors on the left allow to filter the nodes to be displayed on the graph:</p>
        <ul>
          <li><b>Report: </b>
            Display results of the selected report.
          </li>
          <li><b>Datasets: </b>
            Display results of comparative analysis between the two groups (separated by slash "/").
            One dataset relates to two groups.
            Maximum two datasets can be displayed at a time.
          </li>
          <li><b>Super Pathways: </b>
            Display results of biochemicals belonging to the selected super pathways.
            The super pathways are the diamonds.
            An infinite number of super pathways can be selected.
          </li>
          <li><b>Sub Pathways: </b>
            Display results of biochemicals belonging to the selected sub pathways.
            The sub pathways are the pentagons.
            An infinite number of sub pathways can be selected.
          </li>
          <li><b>Biochemicals: </b>
            Display results of selected biochemicals.
            The biochemicals are the circles.
            An infinite number of biochemicals can be selected.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Body>
        <p>Node attributes for biochemicals:</p>
        <ul>
          <li><b>Radius: </b>
            <ul>
              <li>Short: Ratio of concentration between group 1 and 2 close to 1.</li>
              <li>Long: Ratio of concentration between group 1 and 2 close to 0 or infinite.</li>
            </ul>
          </li>
          <li><b>Color: </b>
            <ul>
              <li>Green: Ratio of concentration between group 1 and 2 is {'<='} 1 <u>AND</u> significant (p-value {'<='} 0.05).</li>
              <li>Light green: Ratio of concentration between group 1 and 2 is {'<='} 1 <u>AND</u> significant (0.05 {'<'} p-value {'<='} 0.10).</li>
              <li>Red: Ratio of concentration between group 1 and 2 is {'>'} 1 <u>AND</u> significant (p-value {'<='} 0.05).</li>
              <li>Light red: Ratio of concentration between group 1 and 2 is {'>'} 1 <u>AND</u> significant (0.05 {'<'} p-value {'<='} 0.10).</li>
              <li>White: Ratio of concentration not significant.</li>
              <li>Transparent light grey: Ratio not available.</li>
            </ul>
          </li>
        </ul>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CytoscapeModal;
