/* eslint-disable */

export const getObjectByKey = /* GraphQL */ `
  query GetObjectByKey($key: String!) {
    getObjectByKey(key: $key) {
      report
    }
  }
`;

export const listObjects = /* GraphQL */ `
  query ListObjects {
    listObjects {
      Key
    }
  }
`;
