/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});


// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.




const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0d6fb3d9-bd02-45d8-99ff-71fa9d73b7e5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7KJGbGH3I",
    "aws_user_pools_web_client_id": "6ruu09aod6lc9vldbbe7giulpe",
    "oauth": {
        "domain": "auth.lex-comp-biology.analytics.dsm.app",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dashbolome.lex-comp-biology.analytics.dsm.app/",
        "redirectSignOut": "https://dashbolome.lex-comp-biology.analytics.dsm.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://aqqfn3r5yjh5zhdnczfks3k23e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;