const cytoscapeStylesheet = [
  {
    'selector': '.super-pathway',
    'style': {
      'shape': 'round-diamond',
      'label': 'data(label)',
      'font-size': 100,
      'font-weight': 'bold',
      'text-wrap': 'wrap',
      'text-max-width': '500px',
      'text-outline-color': 'white',
      'text-outline-width': 2,
      'background-color': 'black',
      'height': 50,
      'width': 50,
      'z-index': 5000
    }
  },
  {
    'selector': '.sub-pathway',
    'style': {
      'shape': 'round-pentagon',
      'background-color': 'grey',
      'height': 20,
      'width': 20,
      'z-index': 4000
    }
  },
  {
    'selector': '.biochemical',
    'style': {
      'background-opacity': 0,
      'background-image': 'data(svg)',
      'height': 'data(size)',
      'width': 'data(size)',
    }
  },
  {
    'selector': ':selected',
    'style': {
      'border-color': 'blue',
      'border-width': 2,
      'border-opacity': 0.5,
      'z-index': 9997
    }
  },
  {
    'selector': '.tapped',
    'style': {
      'border-color': 'black',
      'border-width': 2,
      'border-style': 'dashed',
      'border-opacity': 0.5,
      'label': 'data(label)',
      'text-outline-color': 'white',
      'text-outline-width': 2,
      'font-size': 50,
      'z-index': 9998
    }
  },
  {
    'selector': '.tapped:selected',
    'style': {
      'border-color': 'blue',
      'border-width': 2,
      'border-style': 'dashed',
      'border-opacity': 0.5,
      'label': 'data(label)',
      'text-outline-color': 'white',
      'text-outline-width': 2,
      'font-size': 50,
      'z-index': 9999
    }
  },
];

export default cytoscapeStylesheet;