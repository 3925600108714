
const encodeSVGAsURI = (svg) => {
    return 'data:image/svg+xml;utf-8,' + encodeURI(svg);
};

const getCircleSVG = (size, color, opacity) => {
  if (size === 1)
    size = 199;
  const svg = `
      <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle cx="${Math.round(size/2)}" cy="${Math.round(size/2)}" r="${Math.round(size/2)}" fill="${color}" fill-opacity="${opacity}"/>
      </svg>
  `;
  return encodeSVGAsURI(svg);
};

// Returns the svg of two half circles which dimensions and colors.
const getTwoHalfCirclesSVG = (size1, size2, color1, color2, opacity1, opacity2) => {
  // Determine max size
  const size = Math.max(size1, size2)
  // X coordinate for all sides
  const x = parseInt(size/2)
  // Y coordinates for side 1
  const y11 = parseInt(size/2) - parseInt(size1/2)
  const y12 = parseInt(size/2) + parseInt(size1/2)
  // Y coordinates for side 2
  const y21 = parseInt(size/2) - parseInt(size2/2)
  const y22 = parseInt(size/2) + parseInt(size2/2)
  const svg = `
      <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M${x},${y11} A1,1 0 0,0 ${x},${y12}" fill="${color1}" fill-opacity="${opacity1}" />
          <path d="M${x},${y21} A1,1 0 0,1 ${x},${y22}" fill="${color2}" fill-opacity="${opacity2}" />
      </svg>
  `
  return encodeSVGAsURI(svg)
}

export { getCircleSVG, getTwoHalfCirclesSVG };