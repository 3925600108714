import * as dfd from 'danfojs';

import { arraysToObject, dfToObjectList, getColor, getOpacity, getCircleSVG, getTwoHalfCirclesSVG } from 'src/utils';


const dfToOptionList = (df, valueCol, labelCol) => {
  if (df.shape[0] === 0) return [];
  df = df.addColumn('count', new Array(df.shape[0]).fill(1));
  if (valueCol === labelCol) {
    df = df.loc({columns: [valueCol, 'count']});
    df = df.groupby([valueCol]).count();
    df = df.addColumn('label', df.column(valueCol));
    df = df.rename({[valueCol]: 'value'}, {axis: 1});
  } else {
    df = df.loc({columns: [labelCol, valueCol, 'count']});
    df = df.groupby([labelCol, valueCol]).count();
    df = df.rename({[valueCol]: 'value', [labelCol]: 'label'}, {axis: 1});
  }
  return dfToObjectList(df);
};

const filterDFMultipleOrConditions = (df, conditions) => {
  if (conditions.length === 0) return df;
  let dfs = [];
  conditions.forEach(condition => {
    dfs.push(df.query(condition));
  });
  dfs = dfs.filter(df => df.shape[0] > 0);
  if (dfs.length === 0) return new dfd.DataFrame([]);  
  df = dfd.concat({dfList: dfs, axis: 0}).resetIndex();
  return df;
};

const getSuperPathwayDF = (df) => {
  df = df.loc({columns: ['Super Pathway', 'Super Pathway ID']});
  df = df.addColumn('count', new Array(df.shape[0]).fill(1));
  df = df.groupby(['Super Pathway', 'Super Pathway ID']);
  df = df.col(['count']).count();
  return df;
};

const getSubPathwayDF = (df) => {
  df = df.loc({columns: ['Super Pathway', 'Super Pathway ID', 'Sub Pathway', 'Sub Pathway ID']});
  df = df.addColumn('count', new Array(df.shape[0]).fill(1));
  df = df.groupby(['Super Pathway', 'Super Pathway ID', 'Sub Pathway', 'Sub Pathway ID']);
  df = df.col(['count']).count();
  return df;
};

const filterDF = (df, selectedItems) => {
  // Return empty df in case
  if (df.size === 0) return df;
  // Filter df for selected dataset
  if (selectedItems.datasets) {
    const datasets = selectedItems.datasets.map(o => o.value);
    const datasetConditions = datasets.map(dataset => df['Dataset'].eq(dataset));
    df = filterDFMultipleOrConditions(df, datasetConditions).resetIndex();
    // Return empty df in case
    if (df.size === 0) return df;
  }
  // Filter df for selected super pathways
  if (selectedItems.superPathways) {
    const superPathwaysIDs = selectedItems.superPathways.map(o => o.value);
    const superPathwayConditions = superPathwaysIDs.map(sID => df['Super Pathway ID'].eq(sID));
    df = filterDFMultipleOrConditions(df, superPathwayConditions).resetIndex();
  }
  // Filter df for selected sub pathways
  if (selectedItems.subPathways) {
    const subPathwaysIDs = selectedItems.subPathways.map(o => o.value);
    const subPathwayConditions = subPathwaysIDs.map(sID => df['Sub Pathway ID'].eq(sID));
    df = filterDFMultipleOrConditions(df, subPathwayConditions).resetIndex();
  }
  // Filter df for selected biochemicals
  if (selectedItems.biochemicals) {
    const compIDs = selectedItems.biochemicals.map(o => o.value);
    const biochemicalConditions = compIDs.map(cID => df['Comp ID'].eq(cID));
    df = filterDFMultipleOrConditions(df, biochemicalConditions);
  }
  return df;
};

const dfWithColumnColor = (df, family, signMetric) => {
  // Return empty df in case
  if (df.size === 0) return df;
  // Create new column color
  df = df.addColumn(
    'color',
    dfToObjectList(df, df.columns).map(row => {
      return getColor(row['ratio'], row[signMetric], row['Comp ID'], family)
    }, { axis: 0 })
  );
  // Create new column opacity
  df = df.addColumn(
    'opacity',
    dfToObjectList(df, df.columns).map(row => {
      return getOpacity(row['ratio'])
    }, { axis: 0 })
  );
  return df;
};

const getSuperPathwayNodes = (df) => {
  const columns = df.columns;
  const nodes = df.values.map(values => {
    const item = arraysToObject(columns, values);
    return {
      'data': {
        'id': item['Super Pathway ID'], 'label': item['Super Pathway'],
        'super-pathway-id': item['Super Pathway ID']
      },
      'classes': 'super-pathway'
    };
  });
  return nodes;
};

const getSubPathwayNodes = (df) => {
  const columns = df.columns;
  const nodes = df.values.map(values => {
    const item = arraysToObject(columns, values);
    return {
      'data': {
          'id': item['Sub Pathway ID'], 'label': item['Sub Pathway'],
          'sub-pathway-id': item['Sub Pathway ID'],
          'super-pathway-id': item['Super Pathway ID']
      },
      'classes': 'sub-pathway'
    }
  });
  return nodes;
};

const getBiochemicalNodes = (df) => {
  const columns = df.columns;
  const nodes = df.values.map(values => {
    const item = arraysToObject(columns, values);
    let size, svg;
    if (item['ratio_1'] === undefined) {
      size = item['size'];
      svg = getCircleSVG(item['size'], item['color'], item['opacity']);
    } else {
      size = Math.max(item['size'], item['size_1']);
      svg = getTwoHalfCirclesSVG(item['size'], item['size_1'], item['color'], item['color_1'], item['opacity'], item['opacity_1']);
    }
    return {
      'data': {
          'id': item['Comp ID'],
          'label': item['Biochemical Name'],
          'name': item['Biochemical Name'],
          'sub-pathway-id': item['Sub Pathway ID'],
          'sub-pathway': item['Sub Pathway'],
          'super-pathway-id': item['Super Pathway ID'],
          'super-pathway': item['Super Pathway'],
          'ratio': item['ratio'],
          'p-value': item['p-value'],
          'q-value': item['q-value'],
          'ratio1': item['ratio_1'],
          'p-value1': item['p-value_1'],
          'q-value1': item['q-value_1'],
          'size': size,
          'svg': svg
      },
      'classes': 'biochemical'
    }
  });
  return nodes;
};

const getSuperToSubPathwayEdges = (df) => {
  const columns = df.columns;
  const edges = df.values.map(values => {
    const item = arraysToObject(columns, values);
    return {
      'data': {
        'id': item['Super Pathway ID'] + '-' + item['Sub Pathway ID'],
        'source': item['Super Pathway ID'],
        'target': item['Sub Pathway ID']
      }
    }
  });
  return edges;
};

const getSubPathwayToBiochemicalEdges = (df) => {
  const columns = df.columns;
  const edges = df.values.map(values => {
    const item = arraysToObject(columns, values);
    return {
      'data': {
          'id': item['Sub Pathway ID'] + '-' + item['Comp ID'],
          'source': item['Sub Pathway ID'],
          'target': item['Comp ID']
      }
    }
  });
  return edges;
};

const objectToDF = (data) => {
  let columns_types = {
    'Dataset': 'string',
    'Biochemical Name': 'string',
    'Comp ID': 'int32',
    'Super Pathway': 'string',
    'Super Pathway ID': 'string',
    'Sub Pathway': 'string',
    'Sub Pathway ID': 'string',
    'p-value': 'float32',
    'q-value': 'float32',
    'ratio': 'float32',
    'size': 'float32'
  }
  let columns = [];
  // Create dataframe
  let df = new dfd.DataFrame(data);
  // Cast columns to types
  Object.keys(columns_types).forEach(col  => {
    if (df.columns.includes(col)) {
      df = df.asType(col, columns_types[col]);
      columns.push(col);
    }
  });
  // Order columns
  df = df.loc({columns: columns})
  return df;
};

const dfToElements = (df) => {
  if (df.size === 0) return [];
  // Get list of selected datasets
  const datasets = df['Dataset'].unique().values;
  // Pivot columns p-value, q-value, ratio and size if 2 datasets are selected
  if (datasets.length === 2) {
    const columns = ['Super Pathway', 'Super Pathway ID', 'Sub Pathway', 'Sub Pathway ID', 'Biochemical Name', 'Comp ID'];
    const df1 = df.query(df['Dataset'].eq(datasets[0]));
    const df2 = df.query(df['Dataset'].eq(datasets[1]));
    df = dfd.merge({left: df1, right: df2, on: columns, how: 'inner'});
  }
  // Get dataframes of Super & Sub Pathways
  const dfSup = getSuperPathwayDF(df);
  const dfSub = getSubPathwayDF(df);
  // Build Cytoscape elements
  const elements = [
    ...getSuperPathwayNodes(dfSup),
    ...getSubPathwayNodes(dfSub),
    ...getBiochemicalNodes(df),
    ...getSuperToSubPathwayEdges(df),
    ...getSuperToSubPathwayEdges(dfSub),
    ...getSubPathwayToBiochemicalEdges(df)
  ];
  return elements;
};

const dfToTableElements = (df, compIDs) => {
  if ((df.size === 0) || ( compIDs.length === 0)) return [];
  // Get list of selected datasets
  const datasets = df['Dataset'].unique().values;
  // Filter df for compIDs
  const biochemicalConditions = compIDs.map(cID => df['Comp ID'].eq(parseInt(cID)));
  df = filterDFMultipleOrConditions(df, biochemicalConditions);
  if (df.size === 0) return [];
  // Pivot columns p-value, q-value, ratio and size if 2 datasets are selected
  if (datasets.length === 2) {
    const columns = ['Super Pathway', 'Super Pathway ID', 'Sub Pathway', 'Sub Pathway ID', 'Biochemical Name', 'Comp ID'];
    const df1 = df.query(df['Dataset'].eq(datasets[0]));
    const df2 = df.query(df['Dataset'].eq(datasets[1]));
    df = dfd.merge({left: df1, right: df2, on: columns, how: 'inner'});
  }
  // Convert df to list of objects
  const elements = dfToObjectList(df, df.columns);
  return elements;
};

export { objectToDF, filterDF, dfWithColumnColor, dfToOptionList, dfToElements, dfToTableElements };