import { Form } from 'react-bootstrap';

import SelectFormGroup from 'Source/components/inputs/SelectFormGroup';
import ToogleButtonGroup from 'Source/components/inputs/ToogleButtonGroup';


const ChartOptions = ({
  reports, selectedReport, setSelectedReport,
  datasets, selectedDatasets, setSelectedDatasets,
  superPathways, selectedSuperPathways, setSelectedSuperPathways,
  subPathways, selectedSubPathways, setSelectedSubPathways,
  biochemicals, selectedBiochemicals, setSelectedBiochemicals,
  families, selectedFamily, setSelectedFamily,
  signMetrics, selectedSignMetric, setSelectedSignMetric, signMetricsDisabled
}) => {

  // console.log('Reload Chart Options...');

  return (
    <Form>
      <SelectFormGroup 
        title={'Report'}
        isMulti={false}
        options={reports}
        optionSelection={selectedReport}
        setOptionSelection={setSelectedReport}
      />
      <SelectFormGroup 
        title={'Datasets'}
        isMulti={true}
        options={datasets}
        optionSelection={selectedDatasets}
        setOptionSelection={setSelectedDatasets}
        limit={[1, 2]}
      />
      <SelectFormGroup 
        title={'Super Pathways'}
        isMulti={true}
        options={superPathways}
        optionSelection={selectedSuperPathways}
        setOptionSelection={setSelectedSuperPathways}
      />
      <SelectFormGroup 
        title={'Sub Pathways'}
        isMulti={true}
        options={subPathways}
        optionSelection={selectedSubPathways}
        setOptionSelection={setSelectedSubPathways}
      />
      <SelectFormGroup 
        title={'Biochemicals'}
        isMulti={true}
        options={biochemicals}
        optionSelection={selectedBiochemicals}
        setOptionSelection={setSelectedBiochemicals}
      />
      <SelectFormGroup 
        title={'Filter for Family'}
        isMulti={false}
        isClearable={true}
        options={families}
        optionSelection={selectedFamily}
        setOptionSelection={setSelectedFamily}
      />
      <ToogleButtonGroup 
        title={'Significance metric'}
        options={signMetrics}
        selectedOption={selectedSignMetric}
        setSelectedOption={setSelectedSignMetric}
        disabled={signMetricsDisabled}
      />
    </Form>
  );
  
}

export { ChartOptions };