// const cytoscapeLayout = {
//   name: 'cose',
//   animate: true,
//   randomize: false,
//   nodeRepulsion: 200000,
//   idealEdgeLength: 150,
//   numIter: 500,
//   gravity: 15,
//   gravityRange: 0.2
// };

// const cytoscapeLayout = {
//   name: 'random'
// };

const cytoscapeLayout = {
  name: 'cose',
  ready: function(){},
  stop: function(){},
  animate: true,
  animationEasing: undefined,
  animationDuration: undefined,
  animateFilter: function ( node, i ){ return true; },
  animationThreshold: 250,
  refresh: 20,
  fit: true,
  padding: 30,
  boundingBox: undefined,
  nodeDimensionsIncludeLabels: false,
  randomize: false,
  componentSpacing: 40,
  nodeRepulsion: function( node ){ return 2048; },
  nodeOverlap: 4,
  edgeElasticity: function( edge ){ return 32; },
  nestingFactor: 1.2,
  gravity: 1,
  numIter: 1000,
  initialTemp: 1000,
  coolingFactor: 0.99,
  minTemp: 1.0
};

export default cytoscapeLayout;