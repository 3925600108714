import { Table } from 'react-bootstrap';


const BiochemicalsTable = ({ biochemicals }) => {

  if (biochemicals.length === 0) return null;

  let columns = ['Super Pathway', 'Sub Pathway', 'Biochemical Name'];

  if (biochemicals[0]['Dataset_1']) {
    columns = columns.concat(['ratio', 'ratio_1', 'p-value', 'p-value_1', 'q-value', 'q-value_1']);
  } else {
    columns = columns.concat(['ratio', 'p-value', 'q-value']);
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {
            columns.map(col => (
              <th>{col}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          biochemicals.map(biochemical => (
            <tr>
              {
                columns.map(col => (
                  <td>{biochemical[col]}</td>
                ))
              }
            </tr> 
          ))
        }
      </tbody>
    </Table>
  );
};

export default BiochemicalsTable;