import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';


const useStateFromGraphQL = (query, condition, variables, getter, defaultValue=undefined) => {

  const [stateVar, setStateVar] = useState(defaultValue);

  useEffect(() => {
    let isMounted = true;
    if (condition) {
      API.graphql({
        query: query,
        variables: variables
      }).then(response => {
        if (!isMounted) {
          return;
        }
        const data = getter(response);
        setStateVar(data);
      }).catch(error => {
        console.log('GraphQL error:');
        console.error(error);
      });
    } else {
      setStateVar(defaultValue);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, condition, variables]);


  // In case we want to return a cancel request function
  // return cancel;

  return stateVar;
}

export { useStateFromGraphQL };