

const PageNotFound = () => {

  return (
    <div>Page not found.</div>
  );
};

const ContentNotFound = () => {

  return (
    <div>Content not found.</div>
  );
};

export {PageNotFound, ContentNotFound};