import { useCallback, useRef, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { FiDownload, FiHelpCircle } from 'react-icons/fi';
import CytoscapeComponent from 'react-cytoscapejs';
import _ from 'lodash';

import { resizeAndFit } from './CytoscapeFunctions';
import cytoscapeStylesheet from './CytoscapeStylesheet';
import cytoscapeLayout from './CytoscapeLayout';
import CytoscapeModal from './CytoscapeModal';


const CytoscapeChart = ({elements, tapNodeIDs, setTapNodeIDs}) => {

  // State: Cytoscape png base64-encoded image
  const [cyPNG, setCyPNG] = useState(null);
  // State: Button disabled
  const [downloadDisabled, setDownloadDisabled] = useState(true);

  const cy = useRef(null);
  // Callback: Called everytime something changes in the chart (user interactions, new elements, etc.)
  const setCytoscape = useCallback(ref => {
    if (ref !== cy.current) {
      // Define refresh layout function (debounced function)
      const REFRESH_LAYOUT_DELAY = 100;
      const refreshLayout = _.debounce(() => {
          cy.current.layout(cytoscapeLayout).run();
      }, REFRESH_LAYOUT_DELAY);
      // Set reference to current cytoscape graph
      cy.current = ref;
      // Add event listener on window resize to resize and fit cytoscape container
      window.addEventListener('resize', e => resizeAndFit(cy.current), false);
      // Add event listener when nodes are added or removed (single node at a time)
      cy.current.on('add remove', () => {
        refreshLayout();
      });
      // Add event listener when layout starts computation
      cy.current.on('layoutstart', () => {
        setCyPNG(null);
        setDownloadDisabled(true);
      });
      // Add event listener when layout finishes computation
      cy.current.on('layoutstop', () => {
        setCyPNG(cy.current.png());
        setDownloadDisabled(false);
      });
      // Add event listener when view is updated
      cy.current.on('mouseup scrollzoom pinchzoom', () => {
        setCyPNG(cy.current.png());
      })
      // Add event listener when node is clicked
      cy.current.on('tap', 'node', event => {
        let node = event.target;
        let nodes = tapNodeIDs;
        if (!nodes.includes(node.id())) {
          nodes.push(node.id());
          node.addClass('tapped');
        } else {
          nodes.splice(nodes.indexOf(node.id()), 1);
          node.removeClass('tapped');
        }
        // Set state
        setTapNodeIDs([...nodes]);
      });
      // Right click on node and select it with all its children
      cy.current.on('cxttapstart', 'node', event => {
        cy.current.$(':selected').unselect();
        let nodesToSelect = cy.current.collection();
        let nextNodes = [event.target];
        while (nextNodes.length !== 0) {
          let currentNode = nextNodes.shift();
          let currentNodeClass = currentNode.classes().filter(cls => ['super-pathway', 'sub-pathway', 'biochemical'].includes(cls)).shift();
          if (currentNodeClass !== undefined) {
            nodesToSelect.merge(currentNode);
            if (currentNodeClass === 'super-pathway') {
              nextNodes.push(...currentNode.neighborhood().filter(node => node.classes().includes('sub-pathway')));
            } else if (currentNodeClass === 'sub-pathway') {
              nextNodes.push(...currentNode.neighborhood().filter(node => node.classes().includes('biochemical')));
            }
          }
        }
        nodesToSelect.select();
      });
    }
    return () => {
      window.removeEventListener('resize', e => resizeAndFit(cy.current), false);
      cy.current.removeListener('add remove');
      cy.current.removeListener('layoutstart');
      cy.current.removeListener('layoutstop');
      cy.current.removeListener('mouseup scrollzoom pinchzoom');
      cy.current.removeListener('tap');
      cy.current.removeListener('cxttapstart');
      cy.current = null;
    }
  }, [cy, tapNodeIDs, setTapNodeIDs]);

  // State: Show state of modal
  const [showModal, setShowModal] = useState(false);

  // Modal handlers
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <CytoscapeComponent
        className="cytoscape"
        elements={elements}
        stylesheet={cytoscapeStylesheet}
        layout={cytoscapeLayout}
        cy={setCytoscape}
        wheelSensitivity={0.2}
      />

      <ButtonToolbar className="download-button">
        <ButtonGroup className="me-2">
          <Button
            variant="primary"
            download="Cytoscape.png"
            href={cyPNG}
            disabled={downloadDisabled}
          >
            <FiDownload />
          </Button>
        </ButtonGroup>
        <ButtonGroup className="me-2">
          <Button variant="primary" onClick={handleShowModal}>
            <FiHelpCircle />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      
      <CytoscapeModal show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default CytoscapeChart;
