import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BsList, BsPersonFill } from 'react-icons/bs';
import { Authenticator } from '@aws-amplify/ui-react';

import dashbolomeLogo from 'Images/DashBolome.svg';
import './Navigation.scss';


const Navigation = () => {

  return (
    <Navbar variant="dark" expand="lg">
      <Container fluid>
        {/* Navbar brand */}
        <Navbar.Brand href="#">
          <img className="d-inline-block align-text-top" src={dashbolomeLogo} alt="DashBolome"/>
        </Navbar.Brand>
        {/* Navbar toggler (for small screens) */}
        <Navbar.Toggle className="btn btn-outline-light" aria-controls="navbarScroll">
          <BsList size={30} />
        </Navbar.Toggle>
        {/* Navbar items */}
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto mb-2 mb-lg-0" navbarScroll>
            <Nav.Link as={Link} to='/' active={true}>Home</Nav.Link>
            <Nav.Link as={Link} to='/upload' active={true}>Upload</Nav.Link>
          </Nav>
          <Nav className="mb-2 mb-lg-0" navbarScroll>
            <NavDropdown id="navbarScrollingDropdownUser" title={<BsPersonFill size={30} />} active={true} align="end">
            <Authenticator>
              {({ signOut, user }) => (
                <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
              )}
            </Authenticator>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;