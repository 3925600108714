import { Form, ButtonGroup, ToggleButton } from 'react-bootstrap';


const ToogleButtonGroup = ({title, options, selectedOption, setSelectedOption, disabled}) => {

  return (
    <Form.Group className="mb-3">
      <Form.Label>{ title }</Form.Label>
      <ButtonGroup className="d-flex">
        {options.map((option, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="light"
            name="radio"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={(e) => setSelectedOption(e.currentTarget.value)}
            disabled={disabled}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Form.Group>
  );
};

export default ToogleButtonGroup;