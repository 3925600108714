import DSMLogo from 'Images/DSM_SimplifiedLogo_Reversed.svg';
import './Footer.scss';


const Footer = () => {
  return (
    <div id="brand-footer" className="row brand-footer">
      <div className="col-6 logo-container">
        <svg className="brand-company" viewBox="0 0 200 75" xmlns="http://www.w3.org/2000/svg">
          <image href={DSMLogo} x="0%" y="0%" width="100"/>
          <text x="0%" y="95%" fontSize="10" fontFamily="Fira Sans Regular">
            HEALTH • NUTRITION • SUSTAINABLE LIVING
          </text>
        </svg>
      </div>
      <div className="col-6">
      </div>
    </div>
  );
}

export default Footer;