import nomenclatureData from 'Source/data/nomenclature.json';


const getColor = (ratio, signMetric, compID, family) => {
    if (isNaN(ratio)) {
      return 'Lightgrey';
    } else if ((family != null) && nomenclatureData[family.value]['Comp ID'].includes(compID)) {
      if ((0 <= signMetric) && (signMetric <= 0.05)) {
        if (ratio > 1) {
          return 'SaddleBrown';
        } else {
          return 'SteelBlue';
        }
      } else if ((0.05 < signMetric) && (signMetric <= 0.1)) {
          if (ratio > 1) {
            return 'SandyBrown';
          } else {
            return 'SkyBlue';
          }
      } else {
        return 'White';
      }
    } else {
      if ((0 <= signMetric) && (signMetric <= 0.05)) {
        if (ratio > 1) {
          return 'Red';
        } else {
          return 'Green';
        }
      } else if ((0.05 < signMetric) && (signMetric <= 0.1)) {
          if (ratio > 1) {
            return 'Pink';
          } else {
            return 'Lightgreen';
          }
      } else {
        return 'White';
      }
    }
  };

const getOpacity = (ratio) => {
  if (isNaN(ratio)) {
    return 0.2;
  } else {
    return 1;
  }
};

export { getColor, getOpacity };