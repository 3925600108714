import { useEffect, useMemo, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

import { getObjectByKey, listObjects } from 'Source/graphql/queries';

import { useStateFromGraphQL } from 'Source/Hooks';
import { CytoscapeChart, PlotlyVolcanoChart, PlotlyFoldDistChart } from 'Components/charts';
import { BiochemicalsTable } from 'Components/tables';
import { ChartOptions } from './ChartOptions';
import { nomenclatureData } from 'src/data';
import { objectToDF, filterDF, dfWithColumnColor, dfToOptionList, dfToElements, dfToTableElements } from './functions';

import './Home.scss';


const Home = () => {

  console.log('Refresh Home...');

  // State: Reports & Selected Report
  const reportsVariables = useMemo(() => ({}), []);
  const reportsGetter = response => {
    const reports = response.data.listObjects
        .filter(r => r.Key.endsWith('.json'))
        .map(r => ({label: r.Key.split('/').pop().split('.').shift(), value: r.Key}));
    return reports;
  };
  const reports = useStateFromGraphQL(listObjects, true, reportsVariables, reportsGetter, []);
  const [selectedReport, setSelectedReport] = useState(undefined);

  // State: Report Data
  const reportDataCondition = (selectedReport !== undefined);
  const selectedReportValue = reportDataCondition ? selectedReport.value : '';
  const reportDataVariables = useMemo(
    () => ({
    key: '/' + selectedReportValue
    }),
    [selectedReportValue]
  );
  const reportDataGetter = response => {
    const reportData = JSON.parse(response.data.getObjectByKey.report);
    return reportData;
  };
  const reportData = useStateFromGraphQL(getObjectByKey, reportDataCondition, reportDataVariables, reportDataGetter, undefined);

  // State: Danfo dataframe for report data
  const [df, setDF] = useState(undefined);
  useEffect(() => {
    if ((reportData !== undefined)) {
      const df = objectToDF(reportData);
      setDF(df);
    } else {
      setDF(undefined)
    }
  }, [reportData]);

  // State: Selected Items, Selection options & Filtered df
  const [selectedItems, setSelectedItems] = useState(undefined);
  useEffect(() => {
    if (df !== undefined) {
      setSelectedItems({
        datasets: dfToOptionList(df, 'Dataset', 'Dataset').slice(0, 1),
        superPathways: [],
        subPathways: [],
        biochemicals: []
      });
    } else {
      setSelectedItems({
        datasets: [],
        superPathways: [],
        subPathways: [],
        biochemicals: []
      });
    }
  }, [df]);
  const [selectionOptions, setSelectionOptions] = useState(undefined);
  const [filteredDF, setFilteredDF] = useState(undefined);
  useEffect(() => {
    if ((df !== undefined) && (selectedItems !== undefined)) {
      const datasets = dfToOptionList(df, 'Dataset', 'Dataset');
      let filteredDF = filterDF(df, {datasets: selectedItems.datasets});
      if (filteredDF.size) {
        const superPathways = dfToOptionList(filteredDF, 'Super Pathway ID', 'Super Pathway');
        filteredDF = filterDF(filteredDF, {superPathways: selectedItems.superPathways});
        const subPathways = dfToOptionList(filteredDF, 'Sub Pathway ID', 'Sub Pathway');
        filteredDF = filterDF(filteredDF, {subPathways: selectedItems.subPathways});
        const biochemicals = dfToOptionList(filteredDF, 'Comp ID', 'Biochemical Name');
        filteredDF = filterDF(filteredDF, {biochemicals: selectedItems.biochemicals});
        setSelectionOptions({
          datasets: datasets,
          superPathways: superPathways,
          subPathways: subPathways,
          biochemicals: biochemicals
        });
        setFilteredDF(filteredDF);
      }
    } else {
      setSelectionOptions({
        datasets: [],
        superPathways: [],
        subPathways: [],
        biochemicals: []
      });
    }
  }, [df, selectedItems]);

  // State: Families & Selected Family
  const [families, ] = useState(Object.keys(nomenclatureData).map(f => ({label: f, value: f})));
  const [selectedFamily, setSelectedFamily] = useState();

  // State: Metrics for statistical significance (p-value/q-value)
  const [signMetrics, ] = useState([{label: 'p-value', value: 'p-value'}, {label: 'q-value', value: 'q-value'}]);
  const [selectedSignMetric, setSelectedSignMetric] = useState('p-value');
  const [signMetricsDisabled, setSignMetricsDisabled] = useState(false);
  useEffect(() => {
    if ((reportData !== undefined) && (reportData[selectedSignMetric] !== undefined)) {
      setSignMetricsDisabled(false);
    } else {
      setSignMetricsDisabled(true);
    }
  }, [reportData, selectedSignMetric]);
  
  // State: Colored df
  const [coloredDF, setColoredDF] = useState(undefined);
  useEffect(() => {
    if (filteredDF !== undefined) {
      const coloredDF = dfWithColumnColor(filteredDF, selectedFamily, selectedSignMetric);
      setColoredDF(coloredDF);
    }
  }, [filteredDF, selectedFamily, selectedSignMetric]);

  const [selectedElements, setSelectedElements] = useState([]);
  useEffect(() => {
    if (coloredDF !== undefined) {
      const elements = dfToElements(coloredDF);
      setSelectedElements(elements);
    } else {
      setSelectedElements([]);
    }
  }, [coloredDF]);

  // Set selected items handlers
  // TODO: Improve this update
  const setSelectedBiochemicals = (selectedBiochemicalOptions) => {
    updateSelectedItems('biochemicals', selectedBiochemicalOptions);
  };
  const setSelectedSubPathways = (selectedSubPathwayOptions) => {
    updateSelectedItems('subPathways', selectedSubPathwayOptions);
  };
  const setSelectedSuperPathways = (selectedSuperPathwayOptions) => {
    updateSelectedItems('superPathways', selectedSuperPathwayOptions);
  };
  const setSelectedDatasets = (selectedDatasetOptions) => {
    updateSelectedItems('datasets', selectedDatasetOptions);
  };
  const updateSelectedItems = (selectionKey, selectedOption) => {
    const newSelectedItems = Object.keys(selectedItems).reduce((obj, key, index) => {
      if (selectionKey === key) {
        return {...obj, [key]: selectedOption};
      } else {
        return {...obj, [key]: selectedItems[key]};
      }
    }, {});
    let filteredDF = df;
    if (newSelectedItems.datasets.length) {
      filteredDF = filterDF(filteredDF, {datasets: newSelectedItems.datasets});
      newSelectedItems.datasets = dfToOptionList(filteredDF, 'Dataset', 'Dataset');
    }
    if (newSelectedItems.superPathways.length) {
      filteredDF = filterDF(filteredDF, {superPathways: newSelectedItems.superPathways});
      newSelectedItems.superPathways = dfToOptionList(filteredDF, 'Super Pathway ID', 'Super Pathway');
    }
    if (newSelectedItems.subPathways.length) {
      filteredDF = filterDF(filteredDF, {subPathways: newSelectedItems.subPathways});
      newSelectedItems.subPathways = dfToOptionList(filteredDF, 'Sub Pathway ID', 'Sub Pathway');
    }
    if (newSelectedItems.biochemicals.length) {
      filteredDF = filterDF(filteredDF, {biochemicals: newSelectedItems.biochemicals});
      newSelectedItems.biochemicals = dfToOptionList(filteredDF, 'Comp ID', 'Biochemical Name');
    }
    setSelectedItems(newSelectedItems);
  };

  // State: Array of node IDs tracking which node names should be displayed
  const [tapNodeIDs, setTapNodeIDs] = useState([]);
  // State: Tap biochemicals
  const [tapBiochemicals, setTapBiochemicals] = useState([]);
  useEffect(() => {
    if (filteredDF !== undefined) {
      const compIDs = tapNodeIDs.filter(ID => ID.match(/^(-?)\d+$/));
      const biochemicals = dfToTableElements(filteredDF, compIDs);
      setTapBiochemicals(biochemicals);
    } else {
      setTapBiochemicals([]);
    }
  }, [filteredDF, tapNodeIDs]);

  return (
    <Row className="mt-3 flex-grow-1 gy-4">
      <Col md={3}>
        { selectionOptions && selectedItems &&
          <ChartOptions
            reports={reports}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            datasets={selectionOptions.datasets}
            selectedDatasets={selectedItems.datasets}
            setSelectedDatasets={setSelectedDatasets}
            superPathways={selectionOptions.superPathways}
            selectedSuperPathways={selectedItems.superPathways}
            setSelectedSuperPathways={setSelectedSuperPathways}
            subPathways={selectionOptions.subPathways}
            selectedSubPathways={selectedItems.subPathways}
            setSelectedSubPathways={setSelectedSubPathways}
            biochemicals={selectionOptions.biochemicals}
            selectedBiochemicals={selectedItems.biochemicals}
            setSelectedBiochemicals={setSelectedBiochemicals}
            families={families}
            selectedFamily={selectedFamily}
            setSelectedFamily={setSelectedFamily}
            signMetrics={signMetrics}
            selectedSignMetric={selectedSignMetric}
            setSelectedSignMetric={setSelectedSignMetric}
            signMetricsDisabled={signMetricsDisabled}
          />
        }
      </Col>
      <Col md={9}>
        <Tabs defaultActiveKey="pathway" mountOnEnter={true}>
          <Tab eventKey="pathway" title="Pathway">
            <Row>
              <Col className="pathway-container">
                { (selectedReport &&
                  <CytoscapeChart
                    elements={selectedElements}
                    tapNodeIDs={tapNodeIDs}
                    setTapNodeIDs={setTapNodeIDs}
                  />)
                  || <p>Please select a Metabolon report</p>
                }
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="volcano" title="Volcano" mountOnEnter={true} >
            <Row>
              <Col className="volcano-container">
                { (selectedReport &&
                  <PlotlyVolcanoChart 
                    df={coloredDF}
                    signMetric={selectedSignMetric}
                  />)
                  || <p>Please select a Metabolon report</p>
                }
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="fold-distribution" title="Fold dist." mountOnEnter={true} >
            <Row>
              <Col className="fold-distribution-container">
                { (selectedReport &&
                  <PlotlyFoldDistChart 
                    df={coloredDF}
                  />)
                  || <p>Please select a Metabolon report</p>
                }
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="table" title="Table">
            <Row>
              <Col>
                <BiochemicalsTable biochemicals={tapBiochemicals} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
}

export default Home;