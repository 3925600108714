import { Form } from 'react-bootstrap';
import Select from 'react-select';


const SelectFormGroup = ({title, isMulti, isClearable, options, optionSelection, setOptionSelection, limit}) => {

  // console.log(`Refreshing Select Form Group "${title}"...`);

  // Handler for on change selected item/items
  const onChangeSelection = option => {
    if (limit !== undefined) {
      if ((option.length < limit[0]) || (limit[1] < option.length)) {
        console.log('Limit reached')
        return;
      }
    }
    setOptionSelection(option);
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>{ title }</Form.Label>
      <Select
        isMulti={isMulti}
        isClearable={isClearable}
        value={optionSelection}
        options={options}
        onChange={onChangeSelection}
        closeMenuOnSelect={!isMulti}
      />
    </Form.Group>
  );
};

export default SelectFormGroup;